@import "../../../../../../Project/Camfil/code/ui/styles/Camfil/imports";


$name: '.InsightsListComponent';

#{$name} {
    &--mostpopulararticles {
        #{$name}-tags {
            display: none;
        }

        /*#{$name}-desc {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }*/
    }

    &-puff {
        &Content {
            width: 100%;
        }
    }

    &-puffImgContainer {
        display: block;
/*        width: 150px;*/
    }

    &-puffImg {
        max-width: 100%;
    }

    &-tagBtn {
        margin-right: .5rem;
        color: $gray-600;
        font-size: .8125rem;
        background-color: transparent;
        border: 0;
        cursor: pointer;
    }

    &-dateText {
        color: $gray-600;
    }

    &-showMoreHits {
        font-weight: normal;
        text-transform: none;
        margin-left: .5rem;
    }
}
